import * as turf from "@turf/turf";
import { toast } from "react-toastify";

import { flyToLand } from "./flyToLand";

/**
 * Generates a function comment for the given function body.
 *
 * @param {Viewer} viewer - The Cesium viewer.
 * @param {Array} polygonCoordinates - The array of polygon coordinates.
 * @param {Function} [onComplete] - The callback function to be executed when the flight is complete.
 * @return {Promise} A promise that resolves when the function completes.
 */
const showPolygonAtCoordinates = async (viewer, polygons, lat, lon) => {
  if (!viewer || !polygons || !polygons.length) {
    console.warn("Viewer or polygons data is not available.");
    return;
  }

  const point = turf.point([lon, lat]);
  let foundPolygon = null;

  for (const polygon of polygons) {
    const vertices = polygon.vertices.map(([lat, lon]) => [lon, lat]);
    const polygonGeoJson = turf.polygon([vertices]);

    if (turf.booleanPointInPolygon(point, polygonGeoJson)) {
      foundPolygon = polygon;
      break;
    }
  }

  if (foundPolygon) {
    await flyToLand(viewer, foundPolygon.vertices);

    return true;
  } else {
    toast.info("No polygon found for the given coordinates.");
    return false;
  }
};

export default showPolygonAtCoordinates;
