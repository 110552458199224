/**
 * @typedef {'FEATURED_ENTITIES' | 'CONNECT_WALLET' | 'PROFILE' | 'WELCOME'} ModalTypeKeys
 */

/**
 * @readonly
 * @enum {ModalTypeKeys}
 */
export const MODAL_TYPES = {
  FEATURED_ENTITIES: "FEATURED_ENTITIES",
  CONNECT_WALLET: "CONNECT_WALLET",
  PROFILE: "PROFILE",
  WELCOME: "WELCOME",
};

/**
 * @typedef {'OPEN_MODAL' | 'CLOSE_MODAL' | 'CLOSE_ALL_MODALS' | 'SET_MODAL_PROPS'} ModalActionKeys
 */

/**
 * @readonly
 * @enum {ModalActionKeys}
 */
export const MODAL_ACTIONS = {
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",
  CLOSE_ALL_MODALS: "CLOSE_ALL_MODALS",
  SET_MODAL_PROPS: "SET_MODAL_PROPS",
};
