import PropTypes from "prop-types";
import { useReducer } from "react";

import ModalErrorBoundary from "@components/ModalErrorBoundary";

import { ModalContext } from "./ModalContext";
import { initialState, modalReducer } from "./store/reducer";

/**
 * Modal context provider
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @returns {JSX.Element}
 */
const ModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(modalReducer, initialState);

  return (
    <ModalContext.Provider value={{ state, dispatch }}>
      <ModalErrorBoundary>{children}</ModalErrorBoundary>
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node,
};

export default ModalProvider;
