export const Features = {
  INITIAL: "initial",
  MAP: "map",
  NOTIFICATIONS: "notifications",
  SETTINGS: "settings",
  HELP: "help",
  USER: "user",
  WALLET: "wallet",
  FEATURED_ENTITIES: "featured-entities",
};

export const mode = {
  BUY_LAND: "Buy land",
  EDIT_LAND: "Edit land",
  SELL_LAND: "Sell land",
  MAKE_OFFER_LAND: "Make offer land",
  MAKE_OFFER_ASSET: "Make offer asset",
  BUY_ASSET: "Buy asset",
  EDIT_ASSET: "Edit asset",
  SELL_ASSET: "Sell asset",
};
