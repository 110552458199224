import { Cartesian3, Matrix4 } from "cesium";
import { useCallback, useEffect, useState } from "react";

import { showPolygonAtCoordinates } from "../utils";

const useCameraPosition = (viewer, polygons) => {
  const [shouldLookAtUserLocation, setShouldLookAtUserLocation] =
    useState(false);

  const setCameraPosition = useCallback(
    (position) => {
      if (!shouldLookAtUserLocation) {
        return;
      }

      let target;
      const offset = new Cartesian3(1000.0, -20000, 15000000);
      if (position) {
        const { latitude, longitude } = position.coords;
        target = Cartesian3.fromDegrees(longitude, latitude, 0);
      } else {
        target = new Cartesian3(
          5738667.946228014,
          2316921.189352922,
          1499033.9616696648
        );
      }

      viewer.camera.lookAt(target, offset);
      viewer.camera.lookAtTransform(Matrix4.IDENTITY);
    },
    [viewer, shouldLookAtUserLocation]
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const lat = parseFloat(urlParams.get("lat") || urlParams.get("LAT"));
    const lon = parseFloat(urlParams.get("lon") || urlParams.get("LON"));

    if (!isNaN(lat) && !isNaN(lon)) {
      const polygonFound = showPolygonAtCoordinates(viewer, polygons, lat, lon);
      if (!polygonFound) {
        setShouldLookAtUserLocation(true);
      }
    } else {
      setShouldLookAtUserLocation(true);
    }
  }, [viewer, polygons]);

  useEffect(() => {
    if (!viewer) {
      return;
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => setCameraPosition(position),
        () => setCameraPosition(null)
      );
    } else {
      console.warn("Geolocation API not supported by this browser.");
    }
  }, [setCameraPosition, viewer]);

  return { shouldLookAtUserLocation };
};

export default useCameraPosition;
