import { MODAL_ACTIONS, MODAL_TYPES } from "./constants";

/**
 * @typedef {Object} ModalState
 * @property {Object.<MODAL_TYPES, boolean>} openModals - States of all modals
 * @property {MODAL_TYPES | null} activeModal - Currently active modal
 * @property {Object.<MODAL_TYPES, Object>} modalProps - Props for each modal
 */

/** @type {ModalState} */
export const initialState = {
  openModals: Object.values(MODAL_TYPES).reduce(
    (acc, modalType) => ({
      ...acc,
      [modalType]: false,
    }),
    {}
  ),
  activeModal: null,
  modalProps: {},
};

/**
 * @param {ModalState} state
 * @param {MODAL_ACTIONS} action
 * @returns {ModalState}
 */
export const modalReducer = (state, action) => {
  switch (action.type) {
    case MODAL_ACTIONS.OPEN_MODAL: {
      const { modalType, props } = action.payload;
      return {
        ...state,
        openModals: {
          ...state.openModals,
          [modalType]: true,
        },
        activeModal: modalType,
        modalProps: {
          ...state.modalProps,
          [modalType]: props,
        },
      };
    }

    case MODAL_ACTIONS.CLOSE_MODAL: {
      const { modalType } = action.payload;
      return {
        ...state,
        openModals: {
          ...state.openModals,
          [modalType]: false,
        },
        activeModal: state.activeModal === modalType ? null : state.activeModal,
        modalProps: {
          ...state.modalProps,
          [modalType]: {},
        },
      };
    }

    case MODAL_ACTIONS.CLOSE_ALL_MODALS:
      return {
        ...state,
        openModals: Object.keys(state.openModals).reduce(
          (acc, key) => ({
            ...acc,
            [key]: false,
          }),
          {}
        ),
        activeModal: null,
        modalProps: {},
      };

    case MODAL_ACTIONS.SET_MODAL_PROPS: {
      const { modalType, props } = action.payload;
      return {
        ...state,
        modalProps: {
          ...state.modalProps,
          [modalType]: {
            ...state.modalProps[modalType],
            ...props,
          },
        },
      };
    }

    default:
      return state;
  }
};
