import { useCallback } from "react";

import { useModalActions, useModalState } from "../ModalContext";

/**
 * @typedef {Object} UseModalReturn
 * @property {boolean} isOpen - Whether modal is open
 * @property {Object} props - Modal props
 * @property {() => void} open - Open modal function
 * @property {() => void} close - Close modal function
 * @property {(props: Object) => void} setProps - Set modal props function
 */

/**
 * Hook for managing a specific modal
 * @param {MODAL_TYPES} modalType - Type of modal to manage
 * @returns {UseModalReturn} Modal controls
 */
export const useModal = (modalType) => {
  const { isOpen, getProps } = useModalState();
  const { openModal, closeModal, setModalProps } = useModalActions();

  const open = useCallback(
    (props = {}) => openModal(modalType, props),
    [modalType, openModal]
  );
  const close = useCallback(
    () => closeModal(modalType),
    [modalType, closeModal]
  );
  const setProps = useCallback(
    (props) => setModalProps(modalType, props),
    [modalType, setModalProps]
  );

  return {
    isOpen: isOpen(modalType),
    props: getProps(modalType),
    open,
    close,
    setProps,
  };
};
