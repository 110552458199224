import { format } from "date-fns";
import { useEffect, useMemo, useState } from "react";

import { useGetCurrentUser } from "@api/user/hooks";

import Maps from "@features/Maps";
import Profile from "@features/Profile";
import Settings from "@features/Settings";

import Help from "@components/Help";
import Notifications from "@components/Notification";
import UserInfo from "@components/UserInfo";
import WalletInfo from "@components/WalletInfo";
import WelcomeNotification from "@components/WelcomeNotification";

import storage from "@services/storage";

import { useWalletActions, useWalletState } from "@Web3/context/WalletContext";

import { useModal } from "@context/Modals/hooks/useModal";
import { MODAL_TYPES } from "@context/Modals/store/constants";

import { Features } from "@constants/SideBar";
import { clsx, stringToGradient } from "@shared/helpers";
import { useOutsideClick, useWebSocket } from "@shared/hooks";

import exit from "@icons/exit.svg";
import featuredPlanetSelected from "@icons/featured-planet-selected.svg";
import featuredPlanet from "@icons/featured-planet.svg";
import helpSelected from "@icons/help-selected.svg";
import help from "@icons/help.svg";
import logo from "@icons/logo-white.svg";
import logoSelected from "@icons/logo.svg";
import mapSelected from "@icons/map-selected.svg";
import map from "@icons/map.svg";
import notificationSelected from "@icons/notification-selected.svg";
import notification from "@icons/notification.svg";
import settingsSelected from "@icons/settings-selected.svg";
import settings from "@icons/settings.svg";
import userSelected from "@icons/user-selected.svg";
import user from "@icons/user.svg";
import walletSelected from "@icons/wallet-selected.svg";
import wallet from "@icons/wallet.svg";

import styles from "./NavigationSidebar.module.css";

const icons = {
  initial: { default: logo, selected: logoSelected },
  map: { default: map, selected: mapSelected },
  notifications: { default: notification, selected: notificationSelected },
  settings: { default: settings, selected: settingsSelected },
  help: { default: help, selected: helpSelected },
  user: { default: user, selected: userSelected },
  wallet: { default: wallet, selected: walletSelected },
  featuredEntities: {
    default: featuredPlanet,
    selected: featuredPlanetSelected,
  },
};

const initialNotifications = [
  {
    id: 1,
    title: "Welcome to the App",
    date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
    isRead: false,
  },
];

const NavigationSidebar = () => {
  const [selectedIcon, setSelectedIcon] = useState("");
  const [openUserProfile, setOpenUserProfile] = useState(false);
  const [openWelcomeModal, setOpenWelcomeModal] = useState(false);

  const [notifications, setNotifications] = useState(initialNotifications);

  const { open: openFeaturedEntitiesModal } = useModal(
    MODAL_TYPES.FEATURED_ENTITIES
  );

  const { toggleWalletConnectModal, disconnect } = useWalletActions();
  const { currentAddress, isConnectWalletModalOpen } = useWalletState();

  const events = useMemo(
    () => ({
      "disconnect-user": async () => {
        setSelectedIcon("");
        await disconnect();
      },
    }),
    [disconnect]
  );

  useWebSocket(events);

  const ref = useOutsideClick({
    callback: () => setSelectedIcon(""),
    enabled: !!selectedIcon,
  });

  const { data: user } = useGetCurrentUser();

  const handleChangeIcon = (iconKey) => {
    if (selectedIcon === iconKey) {
      setSelectedIcon("");
    } else {
      setSelectedIcon(iconKey);
    }

    if (
      !currentAddress &&
      (iconKey === Features.USER || iconKey === Features.WALLET)
    ) {
      setSelectedIcon("");
      toggleWalletConnectModal();
    }

    if (iconKey === Features.FEATURED_ENTITIES) {
      openFeaturedEntitiesModal();
    }
  };

  useEffect(() => {
    const welcomeNotification = storage.get("welcomeNotification");

    if (welcomeNotification) {
      setNotifications((prevState) =>
        prevState.map((notification) =>
          notification.id === 1
            ? { ...notification, isRead: true }
            : notification
        )
      );
    }
  }, []);

  const unreadNotificationsCount =
    notifications?.filter(({ isRead }) => !isRead)?.length || 0;

  const handleCloseWelcomeNotification = () => {
    setOpenWelcomeModal(false);
    storage.save("welcomeNotification", true);

    setNotifications((prevState) =>
      prevState.map((notification) =>
        notification.id === 1 ? { ...notification, isRead: true } : notification
      )
    );
  };

  return (
    <div ref={ref} className={styles.sidebar}>
      <div className={styles.topOptions}>
        <button
          onClick={() => handleChangeIcon(Features.INITIAL)}
          className={styles.logoBtn}
        >
          <img
            src={
              selectedIcon === "initial"
                ? icons.initial.selected
                : icons.initial.default
            }
            alt="logo"
          />
        </button>
        <div className={styles.optionsWrapper}>
          <button onClick={() => handleChangeIcon(Features.MAP)}>
            <img
              src={
                selectedIcon === Features.MAP
                  ? icons.map.selected
                  : icons.map.default
              }
              alt="map"
            />
          </button>
          <button
            onClick={() => handleChangeIcon(Features.NOTIFICATIONS)}
            className={clsx(
              styles.sidebarToggleNotifications,
              styles.hasNewNotifications
            )}
          >
            <img
              src={
                selectedIcon === Features.NOTIFICATIONS
                  ? icons.notifications.selected
                  : icons.notifications.default
              }
              alt="notifications"
            />
            {unreadNotificationsCount > 0 && (
              <span className={styles.newNotifications} />
            )}
          </button>
          <button onClick={() => handleChangeIcon(Features.SETTINGS)}>
            <img
              src={
                selectedIcon === Features.SETTINGS
                  ? icons.settings.selected
                  : icons.settings.default
              }
              alt="settings"
            />
          </button>
          <button onClick={() => handleChangeIcon(Features.HELP)}>
            <img
              src={
                selectedIcon === Features.HELP
                  ? icons.help.selected
                  : icons.help.default
              }
              alt="help"
            />
          </button>
        </div>
      </div>
      <button
        className={styles.featuredEntitiesIcon}
        onClick={() => handleChangeIcon(Features.FEATURED_ENTITIES)}
      >
        <img src={icons.featuredEntities.selected} alt="planet" />
      </button>
      <div key={user?.updatedAt} className={styles.loginWrapper}>
        <button
          onClick={() => handleChangeIcon(Features.USER)}
          className={styles.hasUserImage}
        >
          {!currentAddress || user?.imageUrl ? (
            <img
              key={user?.imageUrl}
              width={50}
              height={50}
              src={
                user?.imageUrl
                  ? `${user?.imageUrl}?t=${new Date().getTime()}`
                  : selectedIcon === Features.USER
                    ? icons.user.selected
                    : icons.user.default
              }
              alt="user"
            />
          ) : (
            <div
              className={styles.avatar}
              style={{
                backgroundImage: currentAddress
                  ? stringToGradient(currentAddress)
                  : null,
              }}
            />
          )}
        </button>
        <button onClick={() => handleChangeIcon(Features.WALLET)}>
          <img
            src={currentAddress ? icons.wallet.selected : icons.wallet.default}
            alt="wallet"
          />
        </button>
      </div>
      <div
        className={clsx(
          styles.sidebarContent,
          (!selectedIcon ||
            isConnectWalletModalOpen ||
            openUserProfile ||
            selectedIcon === Features.FEATURED_ENTITIES) &&
            styles.hideContent
        )}
      >
        <div className={styles.titleWrapper}>
          <button onClick={() => setSelectedIcon("")}>
            <span className={styles.logoSignature}>Decentralverse AI</span>
            <span className={styles.arrow} />
          </button>
        </div>
        {selectedIcon === Features.INITIAL && (
          <div>
            <a
              href="https://www.decentralverse.io/"
              className={styles.visitLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              Visit Decentralverse AI <img src={exit} alt="exit" />
            </a>
          </div>
        )}
        {selectedIcon === Features.MAP && <Maps userId={user?.id} />}
        {selectedIcon === Features.NOTIFICATIONS && (
          <Notifications
            notifications={notifications}
            onOpen={() => setOpenWelcomeModal(true)}
          />
        )}

        {selectedIcon === Features.SETTINGS && (
          <Settings account={currentAddress} />
        )}

        {selectedIcon === Features.HELP && <Help />}

        {currentAddress && selectedIcon === Features.USER && (
          <UserInfo
            data={user}
            toggleUserProfile={() =>
              setOpenUserProfile((prevState) => !prevState)
            }
          />
        )}
        {currentAddress && selectedIcon === Features.WALLET && (
          <WalletInfo
            address={currentAddress}
            onDisconnect={() => {
              disconnect();
              setSelectedIcon("");
            }}
          />
        )}
      </div>

      {openUserProfile && user && currentAddress && (
        <Profile
          address={currentAddress}
          user={user}
          onProfileSubmit={() => {
            setOpenUserProfile((prevState) => !prevState);
            setSelectedIcon("");
          }}
          onClose={() => {
            setOpenUserProfile((prevState) => !prevState);
            setSelectedIcon("");
          }}
        />
      )}
      {openWelcomeModal && (
        <WelcomeNotification onClose={handleCloseWelcomeNotification} />
      )}
    </div>
  );
};

export default NavigationSidebar;
