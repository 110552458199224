import PropTypes from "prop-types";
import { Component } from "react";
import { toast } from "react-toastify";

/**
 * @typedef {Object} Props
 * @property {React.ReactNode} children - Child components
 */

/**
 * @typedef {Object} State
 * @property {boolean} hasError - Whether an error occurred
 * @property {Error} [error] - The error that occurred
 */

/**
 * Error boundary for modal components that shows toast on error
 * @extends {Component<Props, State>}
 */
class ModalErrorBoundary extends Component {
  /** @type {State} */
  state = {
    hasError: false,
    error: null,
  };

  /**
   * @param {Error} error
   */
  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    };
  }

  /**
   * @param {Error} error
   * @param {React.ErrorInfo} errorInfo
   */
  componentDidCatch(error, errorInfo) {
    console.error("Modal Error:", error, errorInfo);
  }

  /**
   * Reset error state when children change
   * @param {Props} prevProps
   */
  componentDidUpdate(prevProps) {
    if (this.props.children !== prevProps.children && this.state.hasError) {
      this.setState({
        hasError: false,
        error: null,
      });
    }
  }

  render() {
    if (this.state.hasError) {
      toast.error(this.state.error?.message || "An error occurred in modal", {
        toastId: "modal-error",
        autoClose: 5000,
      });

      return this.props.children;
    }

    return this.props.children;
  }
}

ModalErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ModalErrorBoundary;
