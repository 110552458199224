import { createContext, useCallback, useContext } from "react";

// eslint-disable-next-line no-unused-vars
import { MODAL_ACTIONS, MODAL_TYPES } from "./store/constants";

/**
 * @typedef {Object} ModalContextValue
 * @property {import('./store/reducer').ModalState} state - Current modal state
 * @property {function} dispatch - Dispatch function from useReducer
 */

/** @type {React.Context<ModalContextValue>} */
export const ModalContext = createContext(null);

/**
 * @typedef {Object} ModalStateUtils
 * @property {(modalType: MODAL_TYPES) => boolean} isOpen - Check if modal is open
 * @property {MODAL_TYPES | null} activeModal - Currently active modal
 * @property {(modalType: MODAL_TYPES) => Object} getProps - Get props for a modal
 */

/**
 * Hook for accessing modal state
 * @returns {ModalStateUtils}
 */
export const useModalState = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error("useModalState must be used within ModalProvider");
  }

  const { state } = context;

  return {
    isOpen: (modalType) => state.openModals[modalType],
    activeModal: state.activeModal,
    getProps: (modalType) => state.modalProps[modalType] || {},
  };
};

/**
 * @typedef {Object} ModalActionUtils
 * @property {(modalType: MODAL_TYPES, props?: Object) => void} openModal - Open a modal
 * @property {(modalType: MODAL_TYPES) => void} closeModal - Close a modal
 * @property {() => void} closeAllModals - Close all modals
 * @property {(modalType: MODAL_TYPES, props: Object) => void} setModalProps - Set modal props
 */

/**
 * Hook for modal actions
 * @returns {ModalActionUtils}
 */
export const useModalActions = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error("useModalActions must be used within ModalProvider");
  }

  const { dispatch } = context;

  return {
    openModal: useCallback(
      (modalType, props = {}) =>
        dispatch({
          type: MODAL_ACTIONS.OPEN_MODAL,
          payload: { modalType, props },
        }),
      [dispatch]
    ),
    closeModal: useCallback(
      (modalType) =>
        dispatch({
          type: MODAL_ACTIONS.CLOSE_MODAL,
          payload: { modalType },
        }),
      [dispatch]
    ),
    closeAllModals: useCallback(
      () => dispatch({ type: MODAL_ACTIONS.CLOSE_ALL_MODALS }),
      [dispatch]
    ),
    setModalProps: useCallback(
      (modalType, props) =>
        dispatch({
          type: MODAL_ACTIONS.SET_MODAL_PROPS,
          payload: { modalType, props },
        }),
      [dispatch]
    ),
  };
};
